jQuery(document).ready(($) => {
	$('.m_filter__items input[type="checkbox"]').each(function () {
		if ($(this).prop('checked')) {
			$(this)
				.parents('.m_filter__block')
				.addClass('is-active');
		}
	});

	$('.m_filter__title--js').on('click', (event) => {
		const $this = event.target;

		const visible = $($this)
			.parent()
			.find('.m_filter__items')
			.is(':visible');

		if (!visible) {
			$($this)
				.parent()
				.find('.m_filter__items')
				.slideToggle()
				.parent()
				.addClass('is-active');
		} else {
			$($this)
				.parent()
				.find('.m_filter__items')
				.slideToggle()
				.parent()
				.removeClass('is-active');
		}
	});
});
