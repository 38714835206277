import 'owl.carousel';

jQuery(document).ready(($) => {
	$('.productfull__img-thumbs--js').owlCarousel({
		autoplay: false,
		loop: false,
		margin: 10,
		nav: true,
		dots: false,
		autoHeight: false,
		autoHeightClass: 'owl-height',
		navText: ['<svg class="icon icon-arrow-left"><use xlink:href="#arrow-left"></use></svg>', '<svg class="icon icon-arrow-right"><use xlink:href="#arrow-right"></use></svg>'],
		responsive: {
			0: {
				items: 3,
			},
			575: {
				items: 4,
			},
			992: {
				items: 5,
			},
		},
	});
});
