function template(type = 'overlay') {
	return `
	<div class="overlay" data-type="${type}"></div>
	`;
}

export function overlayAddFn(type) {
	const overlay = template(type);
	setTimeout(() => {
		document.body.classList.add('animate');
	}, 100);
	document.body.insertAdjacentHTML('beforeend', overlay);
}

export function overlayRemFn() {
	jQuery(document).ready(($) => {
		$('.overlay').remove();
		$('body').removeClass('animate');
	});
}
