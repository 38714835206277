import 'owl.carousel';
import enquire from 'enquire.js';

jQuery(document).ready(($) => {
	$('.js-m_categories-slider').owlCarousel({
		loop: false,
		nav: true,
		dots: true,
		lazyLoad: true,
		autoHeight: false,
		autoHeightClass: 'owl-height',
		navText: ['<svg class="icon icon-chevron-left"><use xlink:href="#chevron-left"></use></svg>', '<svg class="icon icon-chevron-right"><use xlink:href="#chevron-right"></use></svg>'],
		responsive: {
			0: {
				margin: 20,
				items: 1,
			},
			480: {
				margin: 20,
				items: 2,
			},
			769: {
				margin: 20,
				items: 3,
			},
			1201: {
				margin: 30,
				items: 4,
			},
		},
	});

	const $mobileSlider = $('.m_categories:not(.js-m_categories-slider)');

	enquire.register('screen and (max-width: 768px)', {
		match() {
			$mobileSlider.addClass('owl-carousel owl-theme');
			$mobileSlider.owlCarousel({
				loop: false,
				nav: true,
				dots: true,
				lazyLoad: true,
				autoHeight: false,
				autoHeightClass: 'owl-height',
				navText: ['<svg class="icon icon-chevron-left"><use xlink:href="#chevron-left"></use></svg>', '<svg class="icon icon-chevron-right"><use xlink:href="#chevron-right"></use></svg>'],
				responsive: {
					0: {
						margin: 20,
						items: 2,
					},
					575: {
						margin: 30,
						items: 3,
					},
				},
			});
		},
		unmatch() {
			$mobileSlider.removeClass('owl-carousel owl-theme');
			$mobileSlider.trigger('destroy.owl.carousel');
		},
	});
});
