import enquire from 'enquire.js';

jQuery(document).ready(($) => {
	const $mainNav = $('.mainnav__list--js');

	enquire.register('screen and (max-width: 992px)', {
		match() {
			$mainNav.dropdownMenu({
				classNameSubmenu: 'nav-child',
				drpArrow: false,
				classNameCurrent: 'active',
			});
		},
		unmatch() {
			$mainNav.dropdownMenu({
				classNameSubmenu: 'nav-child',
				drpArrow: false,
				classNameCurrent: 'active',
				destroy: true,
			});
		},
	});
});
