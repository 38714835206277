jQuery(document).ready(($) => {
	if ($('.thank-you--js').length) {
		setTimeout(() => {
			$.magnificPopup.open({
				items: {
					src: $('#orderProduct'), // может быть HTML строкой, jQuery объектом, или CSS селектором
				},
				type: 'inline',
				removalDelay: 160,
				mainClass: 'mfp-top-up',
			});
		}, 100);
	}
});
