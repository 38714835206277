import enquire from 'enquire.js';
import {overlayAddFn, overlayRemFn} from '../overlay/overlay';
const overlay = document.querySelector('.overlay');

function showMenu(menu, burger) {
	burger.addClass('is-active');
	menu.addClass('is-active');
	overlayAddFn('mainnav');
}

function hideMenu(menu, burger) {
	burger.removeClass('is-active');
	menu.removeClass('is-active');
	overlayRemFn();
}

jQuery(document).ready(($) => {
	let $hamburger = $('.hamburger--js');
	let $menu = $('.mainnav');

	enquire.register('screen and (max-width: 992px)', {
		deferSetup: true,
		setup() {
			$hamburger.on('click', (event) => {
				event.preventDefault();
				event.stopPropagation();
				const $this = $(event.target);

				if ($menu.hasClass('is-active')) {
					hideMenu($menu, $hamburger);
				} else {
					showMenu($menu, $this);
				}
			});

			// Hide sidebar on page click/tap.
			$(document).on('click touchend', (event) => {
				if ($(event.target).closest($hamburger).length || $(event.target).closest($menu).length) {
					return;
				}

				if (event.target.dataset.type === 'mainnav') {
					hideMenu($menu, $hamburger);
				}
			});

			$('.menu__mobile-close--js').on('click', (e) => {
				e.preventDefault();
				hideMenu($menu, $hamburger);
			});
		},
	});
}); // end ready
