jQuery(document).ready(($) => {
	$('.quickview--js').magnificPopup({
		type: 'inline',
		fixedContentPos: true,
		fixedBgPos: true,
		overflowY: 'auto',
		closeBtnInside: true,
		preloader: false,
		midClick: true,
		mainClass: 'mfp-scale',
		removalDelay: 160,
		// focus: '#name',
	});
});
