import enquire from 'enquire.js';
const sbMenu = document.querySelector('.sb-menu--js');

jQuery(document).ready(($) => {
	$('.sb-menu__list--js').dropdownMenu({
		classNameSubmenu: 'sb-menu__submenu',
		drpArrow: false,
		classNameCurrent: 'is-active',
	});
});

if (sbMenu) {
	enquire.register('screen and (max-width: 992px)', {
		match() {
			sbMenu.classList.add('mfp-hide');
			sbMenu.classList.add('mfp-white-block');
		},
		unmatch() {
			sbMenu.classList.remove('mfp-hide');
			sbMenu.classList.remove('mfp-white-block');
		},
	});
}
