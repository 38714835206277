import enquire from 'enquire.js';
import {overlayAddFn, overlayRemFn} from '../overlay/overlay';

const searchToggle = document.querySelector('.header__search-toggle--js');
const search = document.querySelector('.m_search--js');
const header = document.querySelector('.header');

function close() {
	search.classList.remove('is-active');
	overlayRemFn();
}

function clickHandler(e) {
	e.preventDefault();

	let click = false;
	if (search.classList.contains('is-active') && !click) {
		click = false;
		close();
	} else {
		click = true;
		search.classList.add('is-active');
		overlayAddFn();

		search.querySelector('.form-control').focus();
	}
}

enquire.register('screen and (max-width: 767px)', {
	match() {
		header.querySelector('.header__bottom > .container').appendChild(search);

		searchToggle.addEventListener('click', clickHandler);
		document.addEventListener('click', (event) => {
			if (event.target.dataset.type === 'overlay' || event.target.dataset.type === 'close') {
				close();
			}
		});
	},
	unmatch() {
		header.querySelector('.header__bottom-middle').appendChild(search);
		searchToggle.removeEventListener('click', clickHandler);
	},
});
