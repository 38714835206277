import './intlTelInput.min';

jQuery(document).ready(($) => {
	const $intlTelInput = $('.phone-flag--js');
	$intlTelInput.after('<div class="valid-feedback" />');

	const output = $('.valid-feedback');
	let text = '';

	$intlTelInput.intlTelInput({
		preferredCountries: ['ru', 'us', 'gb'],
		initialCountry: 'auto',
		geoIpLookup(callback) {
			$.get('https://ipinfo.io', () => {}, 'jsonp').always((resp) => {
				let countryCode = resp && resp.country ? resp.country : '';
				callback(countryCode);
			});
		},
		utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.8/js/utils.js',
	});

	function handleChange() {
		output.show();

		if ($intlTelInput.val() !== '') {
			if ($intlTelInput.intlTelInput('isValidNumber')) {
				text = $intlTelInput.intlTelInput('getNumber');
				$intlTelInput.removeClass('is-invalid');
				$intlTelInput.addClass('is-valid');
				output.removeClass('invalid-feedback').addClass('valid-feedback').html(`${$('.textInternational').text()}: <span class="inter-phone">${text}</span>`);
				$intlTelInput.val($('.inter-phone').text());
			} else {
				$intlTelInput.addClass('is-invalid');
				output.removeClass('valid-feedback').addClass('invalid-feedback').html($('.textInvalid').text());
			}
		}
	}

	$intlTelInput.on('change', handleChange);
	// $intlTelInput.on('keyup', handleChange);
	$intlTelInput.on('countrychange', handleChange);
});
