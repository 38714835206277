import '@babel/polyfill';
import svg4everybody from 'svg4everybody';
svg4everybody();

// Jquery
// import $ from 'jquery';
// window.$ = $;
// window.jQuery = $;

// import './go-top/go-top';

// Jquery Form Styler
import './jquery-formstyler/jquery.formstyler.min';
import './jquery-formstyler/init';

// Magnific Popup
import './magnific-popup/init';

// Owl Carousel
// import './owl-carousel/owl.carousel';
// import './owl-carousel/init';

// Simple ajax form
import './simple-ajax-form-submit/jquery.simple-ajax-form';

// Accordeon menu
import './accordeon-menu';

// International Telephone Input
import './intel-tel-input/intl-tel-input__init';

// Fixed block
// import './fixedblock';

// Bootstrap
import './bootstrap/bootstrap';
